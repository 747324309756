import Layout from "components/layout";
import { ReactNode } from "react";
import { Error } from "shared/components/templates/Error";

const NotFound = () => {
  return (
    <Error
      title="ページが見つかりませんでした🙇‍♂️"
      description="お探しのページはアドレスが間違っているか、削除された可能性があります。"
    />
  );
};

NotFound.getLayout = (page: ReactNode) => {
  return (
    <Layout nav={null} footer={null}>
      {page}
    </Layout>
  );
};

export default NotFound;
